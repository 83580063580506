.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.main-contanerr {
  max-width: 2000px;
  margin: 0 auto;

}

.why-choose-us-main {
  background: linear-gradient(179.96deg, #FFF6EA 0.04%, rgba(255, 255, 255, 0) 150.39%);
}

.subscripsion-plan-info {
  background: linear-gradient(270deg, rgba(102, 102, 102, 0) 8.87%, #5E3200 41.39%);

}

.subscripsion-plan-info-main {
  background-image: url('./imgs/subscription-plan-bg-img.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.loan-option {
  box-shadow: 0px 0px 2px 0px #00000040;
}

.loan-calculator-main {
  background: linear-gradient(179.96deg, #FFF6EA 0.04%, rgba(255, 255, 255, 0) 150.39%);
}

.calculator-main {
  box-shadow: 0px 1px 4.7px 0px #00000040;
}

.testimonial-main {
  background: linear-gradient(179.96deg, #FFF6EA 0.04%, rgba(255, 255, 255, 0) 150.39%);
}

.faqs {
  width: 90%;
}

.faqs .faq {
  margin-top: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
  color: #BD7000;
  font-size: 40px;
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.faqs .faq.open {
  border: 1px solid #DC973130;
  border-radius: 16px;
  background: linear-gradient(179.96deg, #FFF6EA 0.04%, rgba(255, 255, 255, 0) 150.39%);
}

.faqs .faq.open .faq-question {
  color: #BD7000;
}

.contact-us-main {
  background: linear-gradient(270deg, rgba(94, 50, 0, 0) 33.66%, #5E3200 51.41%);
}

.contact-info-main {
  background-image: url('./imgs/contact-us-background-img.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-section-main {
  /* background-image: url('./imgs/final-main-hero-image.svg'); */
  background: linear-gradient(rgba(0, 0, 0, 0.637), rgba(0, 0, 0, 0.507)), 
              url('./imgs/final-main-hero-image.svg') no-repeat center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-us-info-main {
  background-image: url('./imgs/final-about-us-hero-image.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.aapka-vishvas-main {
  background: linear-gradient(179.96deg, #FFF6EA 0.04%, rgba(255, 255, 255, 0) 150.39%);
}

.channel-partner-info-main {
  background-image: url('./imgs/final-channel-partner-hero-image.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.customer-section-main {
  background: linear-gradient(360deg, #FFFFFF 0%, #FFF4E3 100%);
  border: 1px solid #FFCF89;
  box-shadow: 0px 1px 2px 0px #00000040;
  box-shadow: 0px 4px 4px 0px #BD700026;

}

.contact-us-info {
  background: linear-gradient(270deg, rgba(94, 50, 0, 0) 33.66%, #5E3200 51.41%);
}

.contact-us-info-main {
  background-image: url('./imgs/contact-us-hero-back.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.input-box-shadow {
  box-shadow: 0px 4px 3.9px 0px #0000000D;
}

.PhoneInputCountryIconImg {
  width: 30px !important;
}

.PhoneInputCountry {
  display: flex !important;
  gap: 20px !important;
  align-items: center !important;
}

.PhoneInput {
  display: flex !important;
  gap: 20px !important;
  align-items: center !important;
}

.PhoneInputCountrySelect {
  width: 20% !important;
}

.PhoneInputInput {
  padding: 10px 20px !important;
  border-radius: 8px;
  box-shadow: 0px 4px 3.9px 0px #0000000D !important;
  border: 1px solid gray !important;
}

.log-in-screen-bg-effect {
  background: linear-gradient(180deg, rgba(102, 102, 102, 0) 13.23%, #5E3200 57.39%);
}

.log-in-main {
  background-image: url('./imgs/log-in-page-image.svg');
  background-repeat: no-repeat;
}

.log-in-image {
  z-index: -1 !important;
}

.form-control {
  box-shadow: 0px 4px 3.9px 0px #0000000D;
  padding: 20px 10px;
  border: 1px solid #E5E7EB !important;
  width: 100% !important;
}

.check-eligible-main {
  background: linear-gradient(359.96deg, #FFF6EA -50.39%, rgba(255, 255, 255, 0) 99.96%);
  border: 1px solid #9A641630;
}

.eligible-loan-amount-main {
  background: linear-gradient(270deg, #EBFFEE 0%, #FFFFFF 100%);
}

/* Radio Button CSS */
.radio-button {
  display: flex;
  gap: 10px;
  margin: 10px;
  position: relative;
  align-items: center;
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding: 12px 15px;
  box-shadow: 0px 4px 3.9px 0px #0000000D;
  transition: background-color 0.3s ease-in-out;
}

.radio-button input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #ccc;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.radio::before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

/* Changes for selected state */
.radio-button input[type="radio"]:checked+.radio {
  border-color: #BD7000;
  background-color: #fff;
}

.radio-button input[type="radio"]:checked+.radio::before {
  opacity: 1;
}

/* Add text color change and background color for the label when selected */
.radio-button input[type="radio"]:checked~.radio,
.radio-button input[type="radio"]:checked~span {
  color: #BD7000;
}

.radio-button input[type="radio"]:checked+.radio+span {
  color: #BD7000;
}

.radio-button input[type="radio"]:checked {
  background-color: #ff0000 !important;  /* Background color when selected */
}

.subscribe-main{
  background: linear-gradient(359.96deg, #EAEEFF -50.39%, rgba(255, 255, 255, 0) 99.96%);
  border: 1px solid #16459A30;
}

.apply-now-btn{
  box-shadow: 0px 2px 3px 0px #00000040;
}

.personal-loan-info-main{
  background-image: url('./imgs/personal-loan-hero-main.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
.personal-loan-info{
  background: linear-gradient(270deg, rgba(102, 102, 102, 0) 16.91%, #5E3200 48.2%);
}
.document-require-section-main{
  background: linear-gradient(179.96deg, #FFF6EA 0.04%, rgba(255, 255, 255, 0) 150.39%);
  border: 1px solid #DC973130;
}
.company-loan-main{
  background: linear-gradient(359.96deg, rgba(255, 255, 255, 0) -50.39%, #FFFFF0 99.96%);
  border: 1px solid #E3E3CA;
}



/* Radio Button CSS */

.partner-section-main{
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width:768px) {
  .faqs {
    width: 100%;
    margin: 0 auto;
  }

  .subscripsion-plan-info {
    background: linear-gradient(281deg, rgba(102, 102, 102, 0) -75.13%, #5E3200 69.39%);
  }

  .contact-us-main {
    background: linear-gradient(270deg, rgba(94, 50, 0, 0) -35.34%, #5E3200 51.41%);
  }

  .subscripsion-plan-info-main{
    background-image: none !important;
  }

  .faqs .faq .faq-question{
    font-size: 18px !important;
  }

  .faqs .faq.open .faq-answer{
    font-size: 14px !important;
  }

  .contact-us-info {
    background: linear-gradient(254deg, rgba(94, 50, 0, 0) -35.34%, #5E3200 51.41%);
  }

  .personal-loan-info{
    background: linear-gradient(254deg, rgba(94, 50, 0, 0) -35.34%, #5E3200 51.41%);
  }

  .hero-section-main {
    background-size: cover;
  }

  .channel-partner-info-main {
    background: linear-gradient(270deg, rgba(94, 50, 0, 0) -35.34%, #5E3200 51.41%);
  }

  .log-in-screen-bg-effect{
    background: linear-gradient(223deg, rgba(101, 102, 102, 0) -89.77%, #5E3200 56.39%);
  }

  .form-control {
    width: 100% !important;
  }

  .jyuSIr {
    gap: 10px !important;
  }
  .about-us-info-main{
    background-image: none !important;
  }
  .contact-us-info-main{
    background-image: none !important;
  }
  .partner-section-main{
    position: absolute;
    top: 140%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}